
import { defineComponent, PropType, ref } from 'vue';
import { pmCommonApi } from '@/api';

interface AlarmInfo {
    MAC: string;
    Location: string;
    ProjectName: string;
    CreateTime: string;
    Status: number;
    UUID: string;
}
export default defineComponent({
    emits: ['vanish'],
    props: {
        alarmInfo: {
            type: Object as PropType<AlarmInfo>,
            default: () => ({
                MAC: '', Location: '', ProjectName: '', CreateTime: '', Status: ''
            })
        },
        alarmCountTotal: {
            type: Number,
            default: 0
        }
    },
    setup(props, { emit }) {
        const dialogVisible = ref(true);
        // 根据有没有开启自动开门决定词条
        const wordList = props.alarmInfo.Status === 0 ? WordList.AlarmFromXXCheckInTime : WordList.AlarmAutoUnlockActivated;
        function closed() {
            // 进行点击OK或关闭按钮后对应告警记录为已读状态的接口调用
            pmCommonApi.setLogRead({
                UUID: props.alarmInfo.UUID
            }, [() => true, false]);
            // 关闭事件：计数alarmCountCurDialog
            emit('vanish');
        }
        return {
            dialogVisible,
            wordList,
            closed
        };
    }
});
